import PropTypes from "prop-types";

export default function GridContainer(props) {
  let { children, className, fluid, rows, ...rest } = props;
  className = "container " + className;
  if(fluid)
    className = "container-fluid text-center " + className;
  
  if(rows)
    return (
      <div className={className} {...rest}>
        <div className="row align-items-start">
          {children}
        </div>
      </div>
    );
  
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}

GridContainer.defaultProps = {
  className: "",
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  rows: PropTypes.bool,
};