import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '@/styles/Home.module.css'
import Footer from '@/components/Layout/Footer'
import GridContainer from '@/components/Layout/Grid/GridContainer'
import GridItem from '@/components/Layout/Grid/GridItem'

const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  return (
    <>
      <Head>
        <title>MonteiroTI</title>
        <meta property="og:title" content="MonteiroTI"></meta>
        <meta property="twitter:title" content="MonteiroTI"></meta>
        <meta property="description" content="MonteiroTI" />
        <meta property="og:description" content="MonteiroTI" />
        <meta property="twitter:description" content="MonteiroTI"></meta>
        <meta property="og:url" content="https://www.monteiroti.com.br"></meta>
        <meta property="og:image" content="https://www.monteiroti.com.br/img/logo_monteiroti.png"></meta>
        <meta property="twitter:image" content="https://www.monteiroti.com.br/img/logo_monteiroti.png"></meta>
      </Head>
      <GridContainer>
        <main className={`${styles.main} ${inter.className}`}>
          <GridContainer fluid rows>
            <GridItem>
              <Image
                className={styles.logo}
                src="/img/logo_monteiroti.png"
                alt="MonteiroTI"
                width={650}
                height={125}
                priority
              />
            </GridItem>
          </GridContainer>
          <GridContainer fluid rows>
            <GridItem className={`col ${styles.center} mt-4`}>
              <h2>Serviços</h2>
            </GridItem>
          </GridContainer>
          <GridContainer fluid rows>
            <GridItem className={`col ${styles.center}`}>
              <a href="#consultoria_ti" className={`${styles.card}`}>
                <h2>
                  Consultoria TI <span>-&gt;</span>
                </h2>
              </a>              
            </GridItem>
            <GridItem className={`col ${styles.center}`}>
              <a href="#programacao" className={`${styles.card}`} rel="noopener noreferrer">
                <h2>
                  Programação <span>-&gt;</span>
                </h2>
                <p>Desenvolvimento de sistemas web.</p>
              </a> 
            </GridItem>
            <GridItem className={`col ${styles.center}`}>
              <a href="#monitoramento_servidores" className={`${styles.card}`}>
                <h2>
                  Monitoramento de Servidores <span>-&gt;</span>
                </h2>
              </a> 
            </GridItem>
          </GridContainer>
        </main>
      </GridContainer>
      <Footer />
    </>
  )
}