import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import GridContainer from './Grid/GridContainer';

export default function Footer() {
  return (
    <GridContainer>
      <footer className="py-2 my-4">
        <p className="text-center text-body-secondary">© 2017 - {1900 + new Date().getYear()} MonteiroTI. Todos os direitos reservados.</p>
      </footer>
    </GridContainer>
  );
}