import PropTypes from "prop-types";

export default function GridItem(props) {
  let { children, className, ...rest } = props;
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}

GridItem.defaultProps = {
  className: "col",
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};